export default [
  {
    currency: 'AED',
    bic: 'CHASDEFXXXX',
    name: 'J.P. MORGAN SE',
    account: 'DE23501108006160007826',
    id: '50110800 / BLZ',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
    correspondent: 'FIRST ABU DHABI BANK PJSC NBADAEAACPU',
  },
  {
    currency: 'AUD',
    bic: 'CHASDEFXXXX',
    name: 'J.P. MORGAN SE',
    account: 'DE98501108006160007834',
    id: '50110800 / BLZ',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
    correspondent: 'AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED ANZBAU3MXXX',
  },
  {
    currency: 'CAD',
    bic: 'CHASDEFXXXX',
    name: 'J.P. MORGAN SE',
    account: 'DE76501108006160007842',
    id: '50110800 / BLZ',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
    correspondent: 'ROYAL BANK OF CANADA ROYCCAT2XXX',
  },
  {
    currency: 'CHF',
    bic: 'KLARCH22XXX',
    name: 'SWISS NATIONAL BANK',
    account: '',
    id: '83041 / BC',
    country: 'CH',
    category: 'ANYY',
    preferred: 'Yes',
  },
  {
    currency: 'DKK',
    bic: 'CHASDEFXXXX',
    name: 'J.P. MORGAN SE',
    account: 'DE37501108006160007768',
    id: '50110800 / BLZ',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
    correspondent: 'NORDEA DANMARK, FILIAL AF NORDEA BANK ABP, FINLAND NDEADKKKXXX',
  },
  {
    currency: 'EUR',
    bic: 'SECGDEFFXXX',
    name: 'SECB SWISS EURO CLEARING BANK GMBH',
    account: '',
    id: '52420700 / BLZ',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
  },
  {
    currency: 'GBP',
    bic: 'CHASGB2LXXX',
    name: 'JPMORGAN CHASE BANK, N.A.',
    account: 'GB03CHAS60924276921872',
    id: '609242 / SC',
    country: 'GB',
    category: 'ANYY',
    preferred: 'Yes',
  },
  {
    currency: 'HKD',
    bic: 'CHASDEFXXXX',
    name: 'J.P. MORGAN SE',
    account: 'DE05501108006160007859',
    id: '50110800 / DE',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
    correspondent:
      'JPMORGAN CHASE BANK, N.A., HONG KONG BRANCH (ORGANIZED UNDER THE LAWS LIMITED LIABILITY) CHASHKHHXXX',
  },
  {
    currency: 'HUF',
    bic: 'CHASDEFXXXX',
    name: 'J.P. MORGAN SE',
    account: 'DE46501108006160007800',
    id: '50110800 / DE',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
    correspondent: 'UNICREDIT BANK HUNGARY ZRT. BACXHUHBXXX',
  },
  {
    currency: 'JPY',
    bic: 'CHASDEFXXXX',
    name: 'J.P. MORGAN SE',
    account: 'DE13501108006231419406',
    id: '50110800 / DE',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
    correspondent: 'JPMORGAN CHASE BANK, N.A., TOKYO BRANCH CHASJPJTXXX',
  },
  {
    currency: 'MXN',
    bic: 'CHASDEFXXXX',
    name: 'J.P. MORGAN SE',
    account: 'DE58501108006160007875',
    id: '50110800 / DE',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
    correspondent:
      'BANCO SANTANDER MEXICO, S.A., INSTITUCION DE BANCA MULTIPLE, GRUPO FINANCIERO SANTANDER MEXICO BMSXMXMMXXX',
  },
  {
    currency: 'NOK',
    bic: 'CHASDEFXXXX',
    name: 'J.P. MORGAN SE',
    account: 'DE15501108006160007776',
    id: '50110800 / DE',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
    correspondent: 'NORDEA BANK ABP, FILIAL I NORGE NDEANOKKXXX',
  },
  {
    currency: 'PLN',
    bic: 'CHASDEFXXXX',
    name: 'J.P. MORGAN SE',
    account: 'DE68501108006160007792',
    id: '50110800 / DE',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
    correspondent: 'MBANK S.A. (FORMERLY BRE BANK S.A.) BREXPLPWXXX',
  },
  {
    currency: 'SEK',
    bic: 'CHASDEFXXXX',
    name: 'J.P. MORGAN SE',
    account: 'DE90501108006160007784',
    id: '50110800 / DE',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
    correspondent: 'NORDEA BANK ABP, FILIAL I SVERIGE NDEASESSXXX',
  },
  {
    currency: 'TRY',
    bic: 'CHASDEFXXXX',
    name: 'J.P. MORGAN SE',
    account: 'DE35501108006231419398',
    id: '50110800 / DE',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
    correspondent: 'JPMORGAN CHASE BANK, N.A., ISTANBUL BRANCH CHASTRISXXX',
  },
  {
    currency: 'USD',
    bic: 'CHASDEFXXXX',
    name: 'J.P. MORGAN SE',
    account: 'DE17501108006231416618',
    id: '50110800 / BLZ',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
    correspondent: 'JPMORGAN CHASE BANK, N.A. CHASUS33XXX',
  },
  {
    currency: 'USD',
    bic: 'PAGOES22XXX',
    name: 'PAGONXT EMONEY EDE SL',
    account: 'ES8267222000490000000076',
    id: '67220000 / ES',
    country: 'ES',
    category: 'ANYY',
    preferred: 'No',
    correspondent: 'CITIBANK N.A. CITIUS33XXX',
  },
  {
    currency: 'ZAR',
    bic: 'CHASDEFXXXX',
    name: 'J.P. MORGAN SE',
    account: 'DE36501108006160007883',
    id: '50110800 / DE',
    country: 'DE',
    category: 'ANYY',
    preferred: 'Yes',
    correspondent: 'STANDARD BANK OF SOUTH AFRICA LIMITED,THE SBZAZAJJXXX',
  },
]
