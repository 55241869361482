export enum FaqCategories {
  General = 'General',
  Account = 'Account',
  Support = 'Support',
  Partnership = 'Partnership',
}

export default [
  {
    category: FaqCategories.General,
    entries: [1, 2, 3, 4, 5, 6, 7],
  },
  {
    category: FaqCategories.Account,
    entries: [8, 9, 10, 11, 12, 13, 14, 15, 16, 28],
  },
  {
    category: FaqCategories.Support,
    entries: [17, 18, 19, 20, 29, 21, 22, 23, 24],
  },
  {
    category: FaqCategories.Partnership,
    entries: [25, 26],
  },
]
